import { Button } from 'baseui/button'
import { LabelSmall, ParagraphSmall } from 'baseui/typography'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { theme } from '../../theming'
import styles from './QrSign.module.scss'
//@ts-ignore : declarar los tipos
import ReactSignatureCanvas from '@rubricae/react-signature-canvas'
import Alert from 'baseui/icon/alert'
import Check from 'baseui/icon/check'
import { Spinner } from 'baseui/spinner'
import { toaster } from 'baseui/toast'
import socketService from '../../services/socket-service'
import CountdownCloseWindowMessage from '../../components/CountDownCloseWindowMessage'

type requestState = 'idle' | 'loading' | 'success' | 'error'

const QrSign = () => {
  const { t } = useTranslation()
  const ref = useRef<ReactSignatureCanvas>(null)
  const [requestState, setRequestState] = useState<requestState>('idle')
  const [searchParams] = useSearchParams()
  const oId = searchParams.get('oId')
  const sId = searchParams.get('sId')

  const handleRetry = () => {
    setRequestState('idle')
  }

  function converMultiArray(final: any) {
    return final.reduce(
      (acc: any, val: any) =>
        Array.isArray(val)
          ? acc.concat(converMultiArray(val))
          : acc.concat(val),
      []
    )
  }

  const handleSendSignature = async () => {
    // Comprobamos que haya al menos 25 puntos en la firma
    const puntos = converMultiArray(ref.current?.toData())
    if (puntos.length < 25) {
      toaster.negative(t('SIGNATURE_MODAL_TITLE'), {
        autoHideDuration: 3000,
        overrides: {
          Body: {
            style: {
              width: '100%',
              maxWidth: '20rem',
              backgroundColor: `${theme.style.primitives.primary}`,
              position: 'absolute',
              marginTop: '1rem'
            }
          }
        }
      })
      return
    }

    setRequestState('loading')

    let signatureDraw = {
      points: puntos,
      content: ref.current?.getCanvas().toDataURL()
    }
    const emitData = {
      event: 'sendQrSignature',
      message: {
        signature: signatureDraw
      }
    }
    const room = `${oId}@${sId}`

    const socketResponse = await socketService.emitToRoom(room, emitData)
    if (socketResponse) {
      setRequestState('success')
    } else {
      setRequestState('error')
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.idsContainer}>
          <div className={styles.idInner}>
            <LabelSmall>{t('OPERATION_ID')}:</LabelSmall>
            <ParagraphSmall>{oId}</ParagraphSmall>
          </div>
          <div className={styles.idInner}>
            <LabelSmall>{t('SIGNER_ID')}:</LabelSmall>
            <ParagraphSmall>{sId}</ParagraphSmall>
          </div>
        </div>
        {requestState === 'loading' && (
          <div className={styles.statusContainer}>
            <LabelSmall
              style={{
                marginBottom: '2rem'
              }}
            >
              {t('SENDING_SIGNATURE_MESSAGE')}
            </LabelSmall>
            <Spinner $color={theme.style.primitives.primary} $size="40px" />
          </div>
        )}
        {requestState === 'success' && (
          <div className={styles.statusContainer}>
            <div className={styles.succesContainer}>
              <div className={styles.centeredRow}>
                <Check size={60} color={theme.style.primitives.primary} />
                <LabelSmall>
                  Proceso de firma completado correctamente.
                </LabelSmall>
              </div>
              <CountdownCloseWindowMessage requestState={requestState} />
            </div>
          </div>
        )}
        {requestState === 'error' && (
          <div className={styles.statusContainer}>
            <div className={styles.errorContainer}>
              <Alert size={30} color={theme.style.primitives.primary} />
              <ParagraphSmall>{t('SIGNATURE_SEND_ERROR')}</ParagraphSmall>
            </div>
            <Button style={{ marginTop: '1rem' }} onClick={handleRetry}>
              {t('RETRY')}
            </Button>
          </div>
        )}
        {requestState === 'idle' && (
          <div className={styles.signContainer}>
            <ReactSignatureCanvas
              penColor="rgba(0,0,139,0.80)"
              canvasProps={{ width: 300, height: 150 }}
              ref={ref}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            gap: '1rem'
          }}
        >
          {requestState === 'idle' && (
            <Button onClick={handleSendSignature}>{t('ADD_SIGN')}</Button>
          )}
        </div>
      </div>
    </>
  )
}

export default QrSign
