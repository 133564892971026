import './App.scss'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider, Theme } from 'baseui'
import { useEffect, useState } from 'react'

import { getTheme, initTheme, theme } from '../../theming'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import LanguageSelector, { LANGUAGES } from '../../components/LanguageSelector'
import { styletron } from '../../styletron'
import { ToasterContainer } from 'baseui/toast'
import { StepperContextProvider } from '../../contexts/StepperContext'

import { Stepper } from '../../components/Stepper'
import Welcome from '../../components/Welcome'
import Otp from '../Otp/Otp'
import Identification from '../Identification/Identification'
import Sign from '../Sign/Sign'
import FinishedSign from '../FinishedSign/FinishedSign'
import i18n from '../../i18n'
import Validate from '../Validate/Validate'
import EditDocument from '../EditDocument/EditDocument'
import QrSign from '../QrSign/QrSign'

export enum STATES {
  BAD_REQUEST = '400',
  ERROR403 = '403',
  NON_PENDANT_OPERATION = '2400'
}

const App = () => {
  const [searchParams] = useSearchParams()
  const params: any = {}
  searchParams.forEach((value: string, key: string) => {
    params[key] = value
  })

  const [baseTheme, setData] = useState<Theme>(getTheme())
  const [componentLoaded, setComponentLoaded] = useState(false)

  useEffect(() => {
    setLanguageByLangUrlParam()
    setTheme()
    setComponentLoaded(true)
  }, [])

  useEffect(() => {
    if (componentLoaded) {
      // Hacer cualquier llamada asíncrona a la API aqui
    }
  }, [componentLoaded])
  async function setLanguageByLangUrlParam() {
    const languageFinded = LANGUAGES.find((lang) => lang.region === params.lang)
    if (languageFinded) {
      i18n.changeLanguage(languageFinded.region)
    }
  }

  async function setTheme() {
    await initTheme()
    const baseTheme = getTheme()
    setData(baseTheme)
  }

  return componentLoaded ? (
    <div className="App">
      <StyletronProvider value={styletron}>
        <ToasterContainer autoHideDuration={7000}>
          <StepperContextProvider>
            <BaseProvider theme={baseTheme} key="base-provider">
              <header>
                <div className="logo-container">
                  <img
                    height={theme.logo.height}
                    width={theme.logo.width}
                    src={theme.logo.src}
                    alt="Rubricae logo"
                  />
                </div>
                <div className="lang-container">
                  {theme.elements.languageSelector && <LanguageSelector />}
                </div>
              </header>

              <main>
                <Stepper />
                <Routes>
                  <Route path="/" element={<Welcome />} />
                  <Route path="/otp" element={<Otp />} />
                  <Route path="/identification" element={<Identification />} />
                  <Route path="/edit-documents" element={<EditDocument />} />
                  <Route path="/sign" element={<Sign />} />
                  <Route path="/finishedsign" element={<FinishedSign />} />
                  <Route path="/validate" element={<Validate />} />
                  <Route path="/qrSign" element={<QrSign />} />
                </Routes>
              </main>
            </BaseProvider>
          </StepperContextProvider>
        </ToasterContainer>
      </StyletronProvider>
    </div>
  ) : (
    <div className="App">Loading...</div>
  )
}

export default App
