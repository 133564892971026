import { api } from './api'
import config from '../config'

const emitToRoom = async (room: string, emitData: any) => {
  try {
    const response = await api.post(
      `${config.api.socket}/api/v1/socket/${room}`,
      emitData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    // Devolvemos true si se ha creado correctamente, false si no
    return response.status === 201
  } catch (error) {
    return false
  }
}

// Para probar en entorn local, utilizaremos fetch en lugar de axios para
// y un proxy en el package.json "proxy": "https://socketsapi-stage.rubricae.com/"

// const emitToRoom = async (room: string, emitData: any) => {
//   const response = await fetch(`/api/v1/socket/${room}`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(emitData)
//   })

//   return response.status === 201
// }
const socketService = {
  emitToRoom
}

export default socketService
