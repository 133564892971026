import { ParagraphSmall } from 'baseui/typography'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface CountdownCloseWindowMessageProps {
  requestState: 'idle' | 'loading' | 'success' | 'error'
}
const CountdownCloseWindowMessage = ({
  requestState
}: CountdownCloseWindowMessageProps) => {
  const { t } = useTranslation()
  const [seconds, setSeconds] = useState(7)

  useEffect(() => {
    if (requestState === 'success' && seconds > 1) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [requestState, seconds])

  // Cerrar la ventana cuando el contador llegue a 1
  useEffect(() => {
    if (requestState === 'success' && seconds === 1) {
      window.close()
    }
  }, [requestState, seconds])

  return <ParagraphSmall>{t('COUNTDOWN_CLOSE_WINDOW_MESSAGE')}</ParagraphSmall>
}

export default CountdownCloseWindowMessage
