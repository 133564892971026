import { Button } from 'baseui/button'
import {
  FocusOnce,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader
} from 'baseui/modal'
import React, { useEffect, useRef, useState } from 'react'
import socketIO from 'socket.io-client'

//@ts-ignore : declarar los tipos
import ReactSignatureCanvas from '@rubricae/react-signature-canvas'
import { QRCodeCanvas } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import config from '../config'
import { LabelSmall } from 'baseui/typography'
import { Block } from 'baseui/block'

const socket = socketIO(config.api.socket)

type QrSignature = {
  signature: {
    points: Array<any>
    content: string
  }
}
export const SignatureModal = ({
  signModalOpen,
  setSignatureModalOpen,
  setSignature
}: {
  signModalOpen: boolean
  setSignatureModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSignature: React.Dispatch<React.SetStateAction<any>>
}) => {
  const { t } = useTranslation()
  const ref = useRef<ReactSignatureCanvas>(null)
  const [isOpen, setOpen] = useState<boolean>()
  const [signatureFromQr, setSignatureFromQr] = useState<QrSignature | null>(
    null
  )
  const [searchParams] = useSearchParams()
  const lang = searchParams.get('lang')
  const oId = searchParams.get('oId')
  const sId = searchParams.get('sId')

  const qrCodeUrl = `qrsign?lang=${lang}&oId=${oId}&sId=${sId}`

  function converMultiArray(final: any) {
    return final.reduce(
      (acc: any, val: any) =>
        Array.isArray(val)
          ? acc.concat(converMultiArray(val))
          : acc.concat(val),
      []
    )
  }
  const handleAddSign = () => {
    const puntos = converMultiArray(ref.current?.toData())
    if (puntos.length < 25) {
      setOpen(true)
    } else {
      setSignature({
        points: puntos,
        content: ref.current?.getCanvas().toDataURL()
      })
      setSignatureModalOpen(false)
    }
  }
  const reiniciarModal = () => {
    setOpen(false)
    setSignatureModalOpen(false)
  }

  //EFECTO AL RECIBIR FIRMA DESDE QR

  useEffect(() => {
    if (signatureFromQr) {
      setSignature({
        points: signatureFromQr.signature.points,
        content: signatureFromQr.signature.content
      })
      setSignatureModalOpen(false)
    }
  }, [signatureFromQr])

  //EFECTO PARA SUSCRIBIRSE A SALA DE FIRMA
  useEffect(() => {
    const room = `${oId}@${sId}`
    socket.emit('subscribeToRoom', room)

    socket.on('sendQrSignature', (signatureData: QrSignature) => {
      setSignatureFromQr(signatureData)
    })

    return () => {
      socket.off('sendQrSignature')
    }
  }, [])

  return (
    <Modal
      isOpen={signModalOpen}
      onClose={() => {
        setSignatureModalOpen(false)
      }}
      overrides={{
        Root: {
          style: {
            zIndex: 8
          }
        },
        Dialog: {
          style: {
            width: 'fit-content',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '800px',
          padding: '0.5rem',
          gap: '1rem'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: 'fit-content',
              border: '1px solid lightgray',
              margin: '1.5rem 0',
              overflow: 'hidden',
              maxWidth: 300
            }}
          >
            {/* Modal de advertencia de firma no válida */}
            <Modal
              isOpen={isOpen}
              closeable={false}
              overrides={{
                Root: {
                  style: {
                    zIndex: 10
                  }
                }
              }}
            >
              <ModalHeader>{t('SIGNATURE_MODAL_TITLE')}</ModalHeader>
              <FocusOnce>
                <ModalBody>{t('SIGNATURE_MODAL_TEXT')}</ModalBody>
              </FocusOnce>
              <ModalFooter>
                <ModalButton
                  autoFocus
                  onClick={() => {
                    reiniciarModal()
                  }}
                >
                  {t('ACCEPT')}
                </ModalButton>
              </ModalFooter>
            </Modal>

            <ReactSignatureCanvas
              penColor="rgba(0,0,139,0.80)"
              canvasProps={{ width: 300, height: 150 }}
              ref={ref}
            />
          </div>

          <Block
            overrides={{
              Block: {
                style: {
                  display: 'block',
                  '@media screen and (max-width: 768px)': {
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end'
                  }
                }
              }
            }}
          >
            <Button onClick={handleAddSign}>{t('ADD')}</Button>
          </Block>
        </div>
        <Block
          overrides={{
            Block: {
              style: {
                display: 'none',
                '@media screen and (min-width: 768px)': {
                  display: 'block'
                }
              }
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '0 0.5rem',
              margin: '0.5rem 0',
              gap: '1rem'
            }}
          >
            <QRCodeCanvas
              value={`${window?.location?.origin}/${qrCodeUrl}`}
              size={200}
              bgColor="#ffffff"
              fgColor="#000000"
            />
            <div style={{ textAlign: 'center' }}>
              <LabelSmall>{t('SCAN_QR_SIGNATURE_INFO_MESSAGE')}</LabelSmall>
            </div>
          </div>
        </Block>
      </div>
    </Modal>
  )
}
